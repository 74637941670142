import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertClass, setAlertClass] = useState('hidden');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [firstNumber, setFirstNumber] = useState(0);
  const [secondNumber, setSecondNumber] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');

  const generateMathProblem = () => {
    const num1 = Math.floor(Math.random() * 9) + 1; // Menghasilkan angka acak dari 1 hingga 9
    const num2 = Math.floor(Math.random() * 9) + 1; // Menghasilkan angka acak dari 1 hingga 9
    setFirstNumber(num1);
    setSecondNumber(num2);
  };

  useEffect(() => {
    generateMathProblem(); // Generate a new math problem when the component is first rendered
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(userAnswer) !== firstNumber + secondNumber) {
      setAlertClass('bg-red-500 text-white p-2 rounded-md my-4');
      setAlertMessage('Please answer the math question correctly');
      return;
    }

    setIsLoading(true);

    emailjs.send('service_gpml79a', 'template_crro7br', {
      name,
      email,
      message,
    }, 'J_df56F6M-fphhbup')
      .then((response) => {
        console.log('Email sent successfully:', response);
        setAlertClass('bg-green-500 text-white p-2 rounded-md my-4');
        setAlertMessage('Email sent successfully, Please check your email inbox or spam folder for the details.');
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        setAlertClass('bg-red-500 text-white p-2 rounded-md my-4');
        setAlertMessage('Email sending failed, Please Email Manual at <a href="mailto:contact@wsus.me">contact@wsus.me</a>');
      })
      .finally(() => {
        setIsLoading(false);
        setName('');
        setEmail('');
        setMessage('');
        generateMathProblem(); // Generate a new math problem
        setUserAnswer(''); // Reset user's answer
      });
  };

  const handleClear = () => {
    setName('');
    setEmail('');
    setMessage('');
    setAlertClass('hidden');
    setAlertMessage('');
    generateMathProblem(); // Generate a new math problem
    setUserAnswer(''); // Reset user's answer
  };

  return (
    <section className="bg-white p-4 rounded-md">
      <h1 className="text-2xl font-semibold mb-4 text-center">Contact our Team</h1>
      <p className="text-center text-gray-700 mb-4">
        Please use this form to contact us for any questions, website issues, and more. We will respond to you as soon as possible, and <strong>please refrain from engaging in unnecessary activities that waste your time here</strong>
      </p>
      <div className="w-full md:w-96 mx-auto">
        <form name="formContact" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full p-2 border rounded-md"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              E-mail
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-2 border rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email Address"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              id="message"
              className="w-full p-2 border rounded-md h-32"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="mathProblem" className="block text-sm font-medium text-gray-700">
              Solve the Math Problem: {firstNumber} + {secondNumber} =
            </label>
            <input
              type="text"
              id="mathProblem"
              className="w-full p-2 border rounded-md"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Your Answer"
              required
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-md mr-2 hover-bg-blue-700 border-b-0 shadow-none"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send"}
            </button>
            <button
              type="button"
              className="bg-gray-300 p-2 rounded-md hover-bg-gray-400 border-b-0 shadow-none"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </form>
        <div className={alertClass}>
          <div dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
        </div>
      </div>
    </section>
  );
}

export default Contact;