import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const borderStyle = {
    border: '2px solid',
    borderRadius: '20px', // Nilai ini dapat disesuaikan sesuai keinginan Anda
  };

  return (
    <div>
      <h1 className="text-2xl font-bold text-center mt-8">
        <span className="border-b-2 border-black">About WSU</span>
      </h1>
      <div className="m-4 p-4 border rounded-lg mx-auto" style={borderStyle}>
        <p className="text-sm mt-4">
          WSU stands for Web Shorten Url, a website designed to shorten links for easy memorization and convenient use.
          If you have any questions regarding WSU, please don't hesitate to contact us through the{' '}
          <a href="ContactUs" className="text-blue-500">
            ContactUs
          </a>{' '}
          page or via email at{' '}
          <a href="mailto:contact@wsus.me" className="text-blue-500">
            contact@wsus.me
          </a>
          .
        </p>
      </div>
      <h1 className="text-2xl font-bold text-center mt-8">
        <span className="border-b-2 border-black">Profile Team WSU</span>
      </h1>
      <div className="m-4 rounded-lg mx-auto">
        <div className="m-4 p-4 border max-w-sm text-center rounded-lg mx-auto" style={borderStyle}>
          <img
            src="https://storage.wsus.me/logo.jpg"
            className="w-24 h-24 rounded-full mx-auto"
            alt="Triandi"
          />
          <div className="text-xl font-bold mt-4">Triandi</div>
          <div className="text-lg text-green-500">Founder</div>
          <div className="text-base">Informatics Engineering at Raja Ali Haji Maritime University</div>
          <p className="text-sm mt-4">
            <span>• Discussion Squad at <a href='https://www.dicoding.com/users/triandi/academies'><b>Dicoding Indonesia</b></a></span> <br></br>
            <span>• Author at blog <a href='https://natunakode.com/'><b>Natuna Kode</b></a></span> <br></br>
            <span>• Front End Enthusiast</span> 
          </p>
          <div className="mt-4">
            <a href="https://www.instagram.com/triandi.me" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-2xl text-red-500" />
            </a>
            <a href="https://www.facebook.com/triandi.me" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-2xl text-blue-500 ml-4" />
            </a>
            <a href="https://www.linkedin.com/in/triandi" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-2xl text-indigo-500 ml-4" />
            </a>
            <a href="mailto:triandi@natunakode.com">
              <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-gray-500 ml-4" />
            </a>
            <a href="https://bio.triandi.natunakode.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGlobe} className="text-2xl text-green-500 ml-4" />
            </a>
            {/* ... (other social media links) */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
