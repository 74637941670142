import React from 'react';

const TermsOfService = () => {
    return (
        <div>
          <h1 className="text-2xl font-bold text-center mt-8">
            <span className="border-b-2 border-black">Terms of Service</span>
          </h1>
          <div className="m-4 p-4 border border-gray-300 max-w-sm text-center rounded-lg mx-auto">
            <p className="text-sm mt-4">
             COMING SOON
            </p>
          </div>
        </div>
      );
    };

export default TermsOfService;
