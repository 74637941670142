import './App.css';
import React, { useState, useEffect } from 'react';
import Generate from './pages/Generate';
import Redirector from './pages/Redirector';
import Contact from './components/Contact';
import Report from './components/Report-link';
import { Routes, Route } from 'react-router-dom';
import Loading from './Loading'; // Impor komponen Loading
import Header from "./components/Header";
import About from "./components/About";
import Privacy from "./components/Privacy";
import TermsOfService from "./components/TermsOfService";
import Footer from './components/Footer'; // Impor komponen Footer
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loading, setLoading] = useState(true);

  // Simulasikan penundaan sebelum menghentikan loading
  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Matikan loading setelah penundaan
    }, 700);
  }, []);

  return (
    <div className="App">
       <ToastContainer /> {/* Tambahkan komponen ToastContainer di sini */}
      {loading ? (
        <Loading /> // Tampilkan loading spinner selama loading aktif
      ) : (
        <div>
          <Header />
          <Routes>
            <Route exact path="/" element={<Generate />} />
            <Route exact path="/:custom" element={<Redirector />} />
            <Route exact path="/ContactUs" element={<Contact />} />
            <Route exact path="/ReportLink" element={<Report />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/TermsOfService" element={<TermsOfService />} />
            <Route exact path="/Privacy" element={<Privacy />} />
          </Routes>
          {/* Menampilkan komponen VisitsCounter */}
       
          <Footer /> {/* Sertakan komponen Footer di sini */}
        </div>
      )}
    </div>
  );
}

export default App;
